// Breakpoint
@mixin breakpoint($point) {
  @if $point == smallPhones {
    @media screen and (max-width: 479px) {
      @content;
    }
  } @else if $point == smallPhonesUp {
    @media screen and (min-width: 480px) {
      @content;
    }
  } @else if $point == mediumPhones {
    @media screen and (min-width: 480px) and (max-width: 575px) {
      @content;
    }
  } @else if $point == phonesDown {
    @media screen and (max-width: 575px) {
      @content;
    }
  } @else if $point == phonesUp {
    @media screen and (min-width: 576px) {
      @content;
    }
  } @else if $point == largePhones {
    @media screen and (min-width: 576px) and (max-width: 767px) {
      @content;
    }
  } @else if $point == mobile {
    @media screen and (max-width: 767px) {
      @content;
    }
  } @else if $point == mobilelandscape {
    @media screen and (max-width: 767px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == mobileportrait {
    @media screen and (max-width: 767px) and (orientation: portrait) {
      @content;
    }
  } @else if $point == mobileUp {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == tablet {
    @media screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $point == tabletmobile {
    @media screen and (max-width: 991px) {
      @content;
    }
  } @else if $point == smalltablet {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  } @else if $point == small {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  } @else if $point == smallUp {
    @media screen and (min-width: 992px) {
      @content;
    }
  } @else if $point == desktopsDown {
    @media screen and (max-width: 1199px) {
      @content;
    }
  } @else if $point == large {
    @media screen and (min-width: 1200px) {
      @content;
    }
  } @else if $point == largest {
    @media screen and (min-width: 1500px) {
      @content;
    }
  }
}

.desktopOnly {
  display: none !important;

  @include breakpoint(smallUp) {
    display: block !important;
  }
}

.mobileOnly {
  display: none !important;

  @include breakpoint(tabletmobile) {
    display: block !important;
  }
}
