@use "../abstract/responsive" as rwd;
@use "../abstract/colors" as color;

$border-radius-val: 3px; 
$transition: all .4s ease;  

/**
 * Mixin 'font' umożliwia dostosowanie właściwości czcionki.
 *
 * Parametry:
 *   - $size: Rozmiar czcionki (wartość opcjonalna).
 *   - $lh: Wysokość linii (wartość opcjonalna).
 *   - $weight: Grubość czcionki (wartość opcjonalna).
 *   - $color: Kolor tekstu (wartość opcjonalna).
 *   - $font: Rodzina czcionek (wartość opcjonalna).
 *
 * Użycie:
 *   @include font($size: 16px, $lh: 1.5, $weight: bold, $color: #333, $font: 'Arial, sans-serif');
 *
 * Działa na zasadzie warunkowego dodawania właściwości CSS, co pozwala na elastyczne
 * dostosowanie wyglądu czcionki w zależności od potrzeb.
 */
@mixin font(
  $size: false,
  $lh: false,
  $weight: false,
  $color: false,
  $font: false
) {
  @if $size {
    font-size: $size;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $color {
    color: $color;
  }
  @if $font {
    font-family: $font;
  }
}

/**
 * Mixin 'centerer' umożliwia łatwe centralizowanie elementu w kontenerze.
 *
 * Parametry:
 *   - $horizontal: Czy centralizować w poziomie (domyślnie true).
 *   - $vertical: Czy centralizować w pionie (domyślnie true).
 *
 * Użycie:
 *   @include centerer($horizontal: true, $vertical: true);
 *
 * Działa na zasadzie absolutnego pozycjonowania elementu oraz stosuje transformacje CSS,
 * aby osiągnąć efekt centralizacji. Opcjonalne parametry pozwalają decydować, czy centralizować
 * element w poziomie, w pionie lub w obu kierunkach. Domyślnie centralizuje w obu kierunkach.
 */
@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

/**
 * Mixin 'flexCenter' ustawia element na kontenerze z wykorzystaniem flexboxa,
 * aby go wyśrodkować zarówno w pionie, jak i w poziomie.
 *
 * Użycie:
 *   @include flexCenter();
 *
 * Działa poprzez ustawienie właściwości CSS dla flexboxa, aby uzyskać efekt
 * centralizacji elementu zarówno w pionie, jak i w poziomie w obrębie jego kontenera.
 * Jest to przydatne narzędzie do szybkiego osiągnięcia wyśrodkowanego układu.
 */
@mixin flexCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
 * Mixin 'flexValue' ustawia element na kontenerze z wykorzystaniem flexboxa
 * i umożliwia dostosowanie różnych właściwości flexboxa.
 *
 * Parametry:
 *   - $align-items-val: Wartość właściwości 'align-items' (wartość opcjonalna).
 *   - $justify-content-val: Wartość właściwości 'justify-content' (wartość opcjonalna).
 *   - $direction: Wartość właściwości 'flex-direction' (wartość opcjonalna).
 *
 * Użycie:
 *   @include flexValue($align-items-val: center, $justify-content-val: space-between, $direction: row);
 *
 * Domyślnie ustawia 'flex-wrap' na 'wrap', aby elementy flexboxa układały się w jednym wierszu.
 * Działa poprzez ustawienie właściwości CSS dla flexboxa, umożliwiając elastyczne
 * dostosowanie właściwości takie jak 'align-items', 'justify-content' i 'flex-direction'.
 * Parametry są opcjonalne, co pozwala na kontrolowane modyfikowanie jedynie potrzebnych właściwości.
 */
@mixin flexValue(
  $align-items-val: false,
  $justify-content-val: false,
  $direction: false
) {
  display: flex;
  flex-wrap: wrap;
  @if ($align-items-val) {
    align-items: $align-items-val;
  }
  @if ($justify-content-val) {
    justify-content: $justify-content-val;
  }
  @if ($direction) {
    flex-direction: $direction;
  }
}

/**
 * Mixin 'gridColumns' ustawia element na kontenerze z wykorzystaniem grid layoutu
 * i umożliwia dostosowanie kolumn oraz odstępów między nimi.
 *
 * Parametry:
 *   - $columns: Wartość właściwości 'grid-template-columns', określająca kolumny.
 *   - $c-gap: Wartość właściwości 'column-gap', określająca odstęp między kolumnami.
 *   - $r-gap: Wartość właściwości 'row-gap' (wartość opcjonalna), określająca odstęp między wierszami.
 *
 * Użycie:
 *   @include gridColumns(1fr 2fr 1fr, 20px, $r-gap: 10px);
 *
 * Działa poprzez ustawienie właściwości CSS dla grid layoutu, umożliwiając dostosowanie
 * kolumn oraz odstępów między nimi. Parametr $r-gap jest opcjonalny, co pozwala na kontrolowane
 * dodanie odstępu między wierszami. Domyślnie grid nie ma odstępu między wierszami.
 */
@mixin gridColumns($columns, $c-gap, $r-gap: false) {
  display: grid;
  grid-template-columns: $columns;
  column-gap: $c-gap;
  
  @if ($r-gap) {
    row-gap: $r-gap;
  }
}

/**
 * Mixin 'listResetStyle' resetuje domyślne style dla list HTML.
 *
 * Użycie:
 *   @include listResetStyle();
 *
 * Działa poprzez ustawienie właściwości CSS, takich jak 'list-style', 'margin' i 'padding',
 * na wartości zerowe, eliminując domyślne style dla elementów listy.
 */
@mixin listResetStyle() {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Mixin 'position' ustawia element na konkretnej pozycji w układzie strony,
 * umożliwiając dostosowanie dodatkowych właściwości takich jak top, right, bottom i left.
 *
 * Parametry:
 *   - $position: Wartość właściwości 'position', określająca pozycję elementu.
 *   - $top: Wartość właściwości 'top' (wartość opcjonalna), określająca odległość od góry.
 *   - $right: Wartość właściwości 'right' (wartość opcjonalna), określająca odległość od prawej strony.
 *   - $bottom: Wartość właściwości 'bottom' (wartość opcjonalna), określająca odległość od dołu.
 *   - $left: Wartość właściwości 'left' (wartość opcjonalna), określająca odległość od lewej strony.
 *
 * Użycie:
 *   @include position($position: absolute, $top: 0, $left: 0);
 *
 * Działa poprzez ustawienie właściwości CSS dla pozycjonowania elementu, umożliwiając
 * dostosowanie dodatkowych właściwości, takich jak odległość od krawędzi (top, right, bottom, left).
 * Parametry $top, $right, $bottom, $left są opcjonalne, co pozwala na elastyczne ustawianie
 * tylko niektórych właściwości w zależności od potrzeb.
 */
@mixin position(
  $position,
  $top: false,
  $right: false,
  $bottom: false,
  $left: false
) {
  position: $position;
  @if ($top) {
    top: $top;
  }
  @if ($right) {
    right: $right;
  }
  @if ($bottom) {
    bottom: $bottom;
  }
  @if ($left) {
    left: $left;
  }
}

/**
 * Mixin 'absoluteCover' ustawia element na absolutnej pozycji, aby całkowicie pokryć
 * jego kontener, zerując odległość od wszystkich krawędzi.
 *
 * Użycie:
 *   @include absoluteCover();
 *
 * Działa poprzez użycie mixinu 'position', aby ustawić element na absolutnej pozycji
 * i zerować odległość od wszystkich krawędzi (top, right, bottom, left).
 * Jest to przydatne do tworzenia warstw pokrywających cały kontener.
 */
@mixin absoluteCover() {
  @include position(absolute, 0, 0, 0, 0);
}

/**
 * Mixin 'bgCover' ustawia właściwości tła (background) elementu w celu pokrycia jego obszaru
 * i zachowania proporcji obrazu, centrując go i eliminując powtarzanie.
 *
 * Użycie:
 *   @include bgCover();
 *
 * Działa poprzez ustawienie właściwości tła, takich jak 'background-size: cover',
 * 'background-position: center' i 'background-repeat: no-repeat', co pozwala na efektywne
 * pokrycie obszaru elementu tłem, zachowując jednocześnie proporcje obrazu.
 */
@mixin bgCover() {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/**
 * Mixin 'fontelloIcon' ustawia styl ikony z czcionki ikonowej Fontello.
 *
 * Parametry:
 *   - $content: Znak lub kod Unicode reprezentujący ikonę Fontello.
 *   - $color: Kolor ikony (wartość opcjonalna).
 *   - $font-size: Rozmiar czcionki ikony (wartość opcjonalna).
 *
 * Użycie:
 *   @include fontelloIcon("\e123", #333, 20px);
 *
 * Działa poprzez ustawienie właściwości CSS, takich jak 'font-family' dla Fontello,
 * 'content' dla reprezentacji ikony, a także opcjonalnie 'color' i 'font-size'.
 * Parametry $color i $font-size pozwalają dostosować wygląd ikony w zależności od potrzeb.
 */
@mixin fontelloIcon($content, $color, $font-size) {
  font-family: "fontello";
  content: $content;

  @if ($color) {
    color: $color;
  }
  @if ($font-size) {
    font-size: $font-size;
  }
}

/**
 * Mixin 'makeCircle' tworzy element o kształcie okręgu, umożliwiając dostosowanie rozmiaru i koloru tła.
 *
 * Parametry:
 *   - $size: Rozmiar okręgu, określony jako wysokość i szerokość.
 *   - $bg-color: Kolor tła okręgu (wartość opcjonalna).
 *
 * Użycie:
 *   @include makeCircle(50px, $bg-color: #3498db);
 *
 * Działa poprzez ustawienie właściwości CSS, takich jak 'border-radius: 100%' dla
 * uzyskania kształtu okręgu, 'height' i 'width' dla określenia rozmiaru. Opcjonalnie
 * pozwala dostosować kolor tła okręgu.
 */
@mixin makeCircle($size, $bg-color: false) {
  border-radius: 100%;
  height: $size;
  width: $size;

  @if $bg-color {
    background-color: $bg-color;
  }
}

/**
 * Mixin 'showLines' ogranicza ilość widocznych linii tekstu, użyteczny przy obszarach o ograniczonej wysokości.
 *
 * Parametry:
 *   - $amount: Maksymalna ilość linii tekstu, która ma być widoczna.
 *
 * Użycie:
 *   @include showLines(3);
 *
 * Działa poprzez ustawienie właściwości CSS, takich jak 'overflow: hidden', 'text-overflow: ellipsis',
 * 'display: -webkit-box', '-webkit-box-orient: vertical' i '-webkit-line-clamp', aby ograniczyć
 * ilość widocznych linii tekstu do określonej wartości. Jest to przydatne narzędzie do zarządzania
 * tekstem w obszarach o ograniczonej wysokości, np. w nagłówkach z ograniczoną przestrzenią.
 */
@mixin showLines($amount) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $amount;
  // height: $lineH * $amount;
}

/**
 * Mixin 'pushAuto' ustawia automatyczne marginesy po lewej i prawej stronie elementu,
 * co powoduje, że element zostaje wyśrodkowany w kontenerze.
 *
 * Użycie:
 *   @include pushAuto;
 *
 * Działa poprzez ustawienie właściwości CSS, takich jak 'margin-left: auto' i 'margin-right: auto',
 * co powoduje, że element jest wyśrodkowany w kontenerze, gdy szerokość elementu nie zajmuje całego kontenera.
 */
@mixin pushAuto {
  margin-left: auto;
  margin-right: auto;
}

/**
 * Mixin 'photoHover' dodaje efekt hover do obrazka, umożliwiając płynne powiększenie
 * przy najechaniu kursorem. Wykorzystuje media query dla małych ekranów, aby skorygować
 * działanie efektu tylko dla większych ekranów.
 *
 * Użycie:
 *   @include photoHover;
 */
@mixin photoHover() {
  &:hover img {
    @include rwd.breakpoint(smallUp) {
      transform: scale(1.07);
    }
  }

  picture {
    overflow: hidden;
  }

  img {
    @include rwd.breakpoint(smallUp) {
      transform: scale(1);
      transition: $transition;
    }
  }
}

/**
 * Mixin 'photoFullMobile' dostosowuje szerokość i margines obrazka na małych ekranach,
 * aby wypełnić całą dostępną przestrzeń.
 *
 * Użycie:
 *   @include photoFullMobile;
 *
 * Działa poprzez ustawienie szerokości na 100% z dodatkowymi 30 pikselami,
 * aby wypełnić całą dostępną przestrzeń. Dodatkowo, marginesy są dostosowane
 * w taki sposób, aby zachować odpowiednie rozmieszczenie obrazka.
 */
@mixin photoFullMobile() {
  width: calc(100% + 30px);
  margin-left: -15px;
}

/**
 * Mixin 'boxShadow' dodaje efekt cienia do elementu, umożliwiając dostosowanie
 * położenia, rozmiaru i przeźroczystości cienia.
 *
 * Parametry:
 *   - $position: Położenie cienia w osi Y (wartość dodatnia przesuwa cień w dół, wartość ujemna - w górę).
 *   - $size: Rozmiar cienia.
 *   - $alpha: Przeźroczystość cienia, wartość od 0 (przezroczysty) do 1 (pełna widoczność).
 *
 * Użycie:
 *   @include boxShadow(4px, 8px, 0.5);
 *
 * Działa poprzez ustawienie właściwości CSS 'box-shadow' na wartość generowaną
 * na podstawie parametrów $position, $size i $alpha.
 */
@mixin boxShadow($position, $size, $alpha) {
  box-shadow: 0 $position $size 0 rgba(color.$c-black, $alpha);
}

/**
 * Mixin 'lnkHoverLine' dodaje efekt animowanego podkreślenia na linku przy najechaniu,
 * umożliwiając dostosowanie położenia, wysokości i koloru podkreślenia.
 *
 * Parametry:
 *   - $bottom: Odległość podkreślenia od dolnej krawędzi linku.
 *   - $height: Wysokość podkreślenia.
 *   - $bg-color: Kolor podkreślenia (wartość opcjonalna).
 *
 * Użycie:
 *   @include lnkHoverLine(0, 2px, #3498db);
 *
 * Działa poprzez dodanie pseudo-elementu ::after, który reprezentuje animowane
 * podkreślenie linku. Parametry $bottom, $height i $bg-color pozwalają dostosować
 * efekt podkreślenia w zależności od potrzeb.
 */
@mixin lnkHoverLine($bottom, $height, $bg-color) {
  &:after {
    @include position(absolute, false, false, $bottom, 0);
    content: "";
    width: 0;
    transition: $transition;
    height: $height;

    @if $bg-color {
      background-color: $bg-color;
    }
  }

  &:hover:after {
    width: 100%;
  }
}

/**
 * Mixin 'zoom' definiuje styl ikony z efektem powiększenia, umożliwiając dostosowanie
 * koloru, pozycji, rozmiaru, przeźroczystości i innych właściwości.
 *
 * Użycie:
 *   @include zoom;
 *
 * Działa poprzez ustawienie stylu ikony z czcionki Fontello, a także definiuje różne
 * zachowania w zależności od szerokości ekranu (używając mixinu 'breakpoint').
 * Na większych ekranach ikona jest ukrywana, a następnie pojawia się z efektem powiększenia
 * po najechaniu kursorem. Na mniejszych ekranach ikona jest umieszczona w rogu ekranu
 * z tłem i układem centrum.
 */
@mixin zoom {
  @include fontelloIcon("\e841", var(--main-color), false);
  z-index: 2;

  @include rwd.breakpoint(smallUp) {
    @include position(absolute, 50%, false, false, 50%);
    transform: translate(-50%, -50%) scale(0.7);
    transition: $transition;
    opacity: 0;
    font-size: 56px;
  }

  @include rwd.breakpoint(tabletmobile) {
    @include flexCenter;
    @include position(absolute, 5px, 5px, false, false);
    padding: 8px;
    border-radius: $border-radius-val;
    width: 30px;
    height: 30px;
    font-size: 18px;
    background-color: rgba(color.$c-black, 0.4);
    color: color.$c-white;
  }
}

/**
 * Mixin 'simpleLinkBlank' definiuje styl linku, który otwiera się w nowym oknie lub karcie,
 * umożliwiając dostosowanie wyglądu oraz dodanie ikony oznaczającej otwarcie w nowym oknie.
 *
 * Użycie:
 *   @include simpleLinkBlank;
 *
 * Działa poprzez ustawienie różnych właściwości CSS dla linku, takich jak położenie, odstępy,
 * kolor tła i dodanie ikony Fontello symbolizującej otwarcie w nowym oknie. Na mniejszych ekranach
 * ikona zmienia kolor po najechaniu kursorem, aby wskazać interakcję.
 */
@mixin simpleLinkBlank() {
  position: relative;
  padding-right: 40px;

  &:hover:after {
    @include rwd.breakpoint(smallUp) {
      color: var(--main-color);
    }
  }

  &:after {
    @include fontelloIcon("\e82c", color.$c-black, 16px);
    @include centerer(false, true);
    right: 0;
    font-weight: 400;
    line-height: 20px;
    margin: 0 3px 0 9px;

    @include rwd.breakpoint(smallUp) {
      transition: $transition;
    }
  }
}

/**
 * Mixin 'iconBlank' definiuje styl ikony z możliwością otwierania linku w nowym oknie,
 * umożliwiając dostosowanie wyglądu ikony oraz dodanie efektu zmiany koloru po najechaniu kursorem.
 *
 * Użycie:
 *   @include iconBlank;
 *
 * Działa poprzez ustawienie różnych właściwości CSS dla ikony i kontenera linku, a także dodanie
 * ikony Fontello symbolizującej otwarcie w nowym oknie. Na mniejszych ekranach ikona zmienia kolor
 * po najechaniu kursorem, aby wskazać interakcję.
 */
@mixin iconBlank() {
  display: inline;
  position: relative;

  span {
    display: inline;
  }

  &:hover:after {
    @include rwd.breakpoint(smallUp) {
      color: var(--main-color);
    }
  }

  &:after {
    @include fontelloIcon("\e82c", color.$c-black, 16px);
    @include position(relative, -1px, false, false, false);
    font-weight: 400;
    line-height: 20px;  
    margin: 0 3px 0 9px;

    @include rwd.breakpoint(smallUp) {
      transition: $transition;
    }
  }
}

/**
 * Mixin 'placeholderColor' definiuje kolor tekstu dla stylu placeholdera w polach formularza,
 * umożliwiając dostosowanie koloru.
 *
 * Parametry:
 *   - $color: Kolor tekstu placeholdera (wartość opcjonalna, domyślnie kolor tekstu głównego).
 *
 * Użycie:
 *   @include placeholderColor(#999);
 *
 * Działa poprzez ustawienie koloru tekstu placeholdera dla różnych przeglądarek
 * (Chrome, Firefox, Safari, IE). Parametr $color pozwala dostosować kolor tekstu
 * placeholdera w zależności od potrzeb.
 */
@mixin placeholderColor($color: $body-color) {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
}

/**
 * Mixin 'titleLine' definiuje styl linii pod tytułem, umożliwiając dostosowanie położenia,
 * koloru tła oraz innych właściwości.
 *
 * Parametry:
 *   - $top: Odległość linii od górnej krawędzi (wartość opcjonalna, domyślnie 1px).
 *   - $bgColor: Kolor tła linii (wartość opcjonalna, domyślnie zmienna $bgColor).
 *
 * Użycie:
 *   @include titleLine(2px, #3498db);
 *
 * Działa poprzez ustawienie wielu właściwości CSS, takich jak położenie, wysokość, elastyczność
 * i kolor tła. Parametry $top i $bgColor pozwalają dostosować wygląd linii w zależności
 * od potrzeb.
 */
@mixin titleLine($top: 1px, $bgColor: $bgColor) {
  @include position(relative, $top);
  content: "";
  height: 1px;
  flex: 1;
  background-color: $bgColor;
}

/**
 * Mixin 'lnkMoreTile' definiuje styl dla płytki zawierającej link z obrazkiem, umożliwiając
 * dostosowanie wielkości obrazka, rozmiaru tytułu, linii i koloru tekstu.
 *
 * Parametry:
 *   - $photoW: Szerokość obrazka.
 *   - $photoH: Wysokość obrazka (wartość opcjonalna, domyślnie brak ograniczenia).
 *   - $titleFS: Rozmiar czcionki tytułu (wartość opcjonalna, domyślnie 16px).
 *   - $titleLH: Wysokość linii tytułu (wartość opcjonalna, domyślnie 23px).
 *
 * Użycie:
 *   @include lnkMoreTile(150px, 200px, 18px, 25px);
 *
 * Działa poprzez ustawienie różnych właściwości CSS, takich jak układ elastyczny,
 * efekt hover na obrazku, dekorację tekstu, kolor tekstu i inne. Parametry pozwalają
 * dostosować wygląd płytki w zależności od potrzeb.
 */
@mixin lnkMoreTile($photoW, $photoH: false, $titleFS: 16px, $titleLH: 23px) {
  @include flexValue(flex-start);
  @include photoHover;
  text-decoration: none;
  color: $body-color;

  picture {
    width: $photoW;
    @if $photoH {
      height: $photoH;
    }
    margin-right: 20px;
  }

  img {
    // width: 100%;
    // height: 100%;
    object-fit: cover;
  }

  &__title {
    @include font($titleFS, $titleLH);
    margin: -5px 0 0;
    flex: 1;
  }
}

/**
 * Mixin 'border' definiuje styl obramowania, umożliwiając dostosowanie koloru i grubości.
 *
 * Parametry:
 *   - $color: Kolor obramowania.
 *   - $value: Grubość obramowania w jednostkach CSS.
 *
 * Użycie:
 *   @include border(#3498db, 2px);
 *
 * Działa poprzez ustawienie właściwości CSS 'border' z określonym kolorem i grubością.
 * Parametry $color i $value pozwalają dostosować wygląd obramowania w zależności od potrzeb.
 */
@mixin border($color, $value) {
  border: solid $color $value;
}

// Download file
/**
 * Mixin 'lnkFile' definiuje styl dla linku reprezentującego plik do pobrania,
 * umożliwiając dostosowanie koloru, tła, odstępów, zaokrąglenia narożników i innych właściwości.
 *
 * Użycie:
 *   @include lnkFile;
 *
 * Działa poprzez ustawienie różnych właściwości CSS, takich jak układ elastyczny,
 * kolor tekstu, tło, odstępy, zaokrąglenie narożników i efekt hover. Na większych ekranach
 * zmienia kolor tła i tekstu oraz przesuwa ikonę oznaczającą rozmiar pliku w dół, aby wskazać interakcję.
 */
@mixin lnkFile() {
  @include flexValue(center, space-between);
  color: $body-color;
  text-decoration: none;
  background-color: $download-lnk-bg;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 3px;
  transition: $transition;
  position: relative;

  &:hover {
    @include rwd.breakpoint(smallUp) {
      background-color: var(--main-color);
      color: color.$c-white;

      .lnk__size,
      .lnkFile__size {
        &:after {
          margin-top: 5px;
          color: color.$c-white;
        }
      }
    }
  }
}

// Download file - title
/**
 * Mixin 'lnkFileTile' dostosowuje styl dla linku reprezentującego plik do pobrania
 * w kontekście płytki, na większych ekranach.
 *
 * Użycie:
 *   @include lnkFileTile;
 *
 * Działa poprzez dodanie dodatkowych właściwości CSS przy użyciu mixinu 'breakpoint'.
 * Na większych ekranach (mobileUp) dostosowuje odstępy i elastyczność, aby lepiej
 * wpasować link w kontekst płytki.
 */
@mixin lnkFileTile {
  @include rwd.breakpoint(mobileUp) {
    padding-right: 40px;
    flex: 1;
  }
}

// Download file - size
/**
 * Mixin 'lnkFileSize' dostosowuje styl dla rozmiaru pliku w kontekście linku do pobrania,
 * umożliwiając dostosowanie grubości czcionki, położenia ikony i innych właściwości.
 *
 * Użycie:
 *   @include lnkFileSize;
 *
 * Działa poprzez ustawienie różnych właściwości CSS, takich jak grubość czcionki,
 * położenie, odstępy, białą spację i ikonę reprezentującą rozmiar pliku. Na mniejszych
 * ekranach, przy użyciu mixinu 'breakpoint', dostosowuje układ i szerokość dla lepszego
 * dopasowania do kontekstu.
 */
@mixin lnkFileSize() {
  font-weight: 500;
  position: relative;
  padding-right: 33px;
  white-space: nowrap; 

  @include rwd.breakpoint(mobile) {
    @include flexValue(false, flex-end);
    width: 100%;
  }

  &:after {
    @include fontelloIcon("\e818", var(--main-color), 20px);
    @include centerer(false, true);
    right: 0;
    transition: $transition;
    margin-top: -1px;
  }
}

/**
 * Mixin 'borderStyle' definiuje styl obramowania z dostosowywalnym kolorem, paddingiem i zaokrągleniem narożników.
 *
 * Parametry:
 *   - $color: Kolor obramowania (wartość opcjonalna, domyślnie zmienna --event-wro-button-gray-border-color).
 *
 * Użycie:
 *   @include borderStyle(#3498db);
 *
 * Działa poprzez ustawienie właściwości CSS 'border', 'padding' i 'border-radius' z określonym kolorem,
 * paddingiem oraz zaokrągleniem narożników. Parametr $color pozwala dostosować kolor obramowania.
 */
@mixin borderStyle($color: var(--event-wro-button-gray-border-color)) {
  border: 1px solid $color;
  padding: 9px 20px 8px 15px;
  border-radius: 3px;
}

/**
 * Mixin 'selectWrapper' dostosowuje styl kontenera dla elementu <select>,
 * umożliwiając dodanie ozdobnego trójkątnego wskaźnika w kolorze głównym.
 *
 * Użycie:
 *   @include selectWrapper;
 *
 * Działa poprzez ustawienie właściwości CSS dla pozycji względnej kontenera
 * oraz dodanie trójkątnego wskaźnika za pomocą pseudo-elementu ':after'.
 * Na mniejszych ekranach, przy użyciu mixinu 'breakpoint', dostosowuje pozycję
 * trójkątnego wskaźnika dla lepszego dopasowania.
 */
@mixin selectWrapper {
  position: relative;

  &:after {
    @include position(absolute, 15px, 10px, false, false);
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid var(--main-color);
    pointer-events: none;

    @include rwd.breakpoint(mobile) {
      top: 14px;  
    }
  }
}

/**
 * Mixin 'changeGutter' dostosowuje odstępy w kontenerze siatkowym (grid),
 * umożliwiając zmianę marginesów i wewnętrznych odstępów kolumn.
 *
 * Parametry:
 *   - $size: Wielkość nowego odstępu (wartość opcjonalna, domyślnie 30px).
 *
 * Użycie:
 *   @include changeGutter(40px);
 *
 * Działa poprzez ustawienie marginesów na ujemne wartości, aby przesunąć kolumny
 * w lewo, oraz ustawienie nowych wewnętrznych odstępów dla kolumn, co pozwala
 * dostosować odstępy w kontekście siatki. Parametr $size pozwala dostosować wielkość
 * nowego odstępu.
 */
@mixin changeGutter($size: 30px) {
  margin-left: -($size / 2) !important;
  margin-right: -($size / 2) !important;

  > [class*="col-"] {
    padding-left: ($size / 2) !important;
    padding-right: ($size / 2) !important;
  }
}

/**
 * Mixin 'sliderLoader' dodaje efekt ładowania do wskazanego elementu,
 * wstawiając animowany wskaźnik ładowania i dostosowując jego stylizację.
 *
 * Parametry:
 *   - $elName: Nazwa selektora CSS elementu, do którego ma być dodany efekt ładowania.
 *   - $color: Kolor wskaźnika ładowania, może być 'light' lub 'dark'. Domyślnie 'light'.
 *
 * Użycie:
 *   @include sliderLoader('.slider');
 *   @include sliderLoader('.slider', 'dark');
 *
 * Działa poprzez:
 * - Ustawienie elementu ($elName) w trybie relative, co pozwala na absolutne pozycjonowanie jego pseudoelementu.
 * - Dodanie przejścia zmiany przezroczystości (opacity) dla płynnego efektu pojawiania i znikania.
 * - Wstawienie pseudoelementu ::before, który:
 *   - Pozycjonowany jest absolutnie w środku elementu.
 *   - Ma ustawione tło z obrazem ładowania, wybieranym na podstawie parametru $color.
 *   - Ma określony rozmiar, wyśrodkowanie i z-index.
 * - Ukrycie elementów z atrybutem [data-hide-and-show], ustawiając ich przezroczystość (opacity) na 0 i ukrywając je (visibility: hidden).
 */
@mixin sliderLoader($elName, $color: light) {
  // Mapa do walidacji i mapowania kolorów na odpowiednie nazwy plików
  $loader-colors: (
    "light": "loading-white.svg",
    "dark": "loading-dark.svg"
  );

  // Pobieranie odpowiedniego pliku na podstawie podanego koloru
  $loader-file: map-get($loader-colors, $color);

  // Sprawdzenie, czy kolor jest poprawny
  @if $loader-file == null {
    @error "Invalid color: #{$color}. Valid colors are light, dark.";
  }

  position: relative;

  #{$elName} {
    transition: opacity 0.1s ease-in-out;

    &:before {
      @include position(absolute, 50%, false, false, 50%);
      background-image: url("https://www.wroclaw.pl/beta2/themes/img/#{$loader-file}");
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      display: block;
      height: 100px;
      margin-top: -20px;
      transform: translate(-50%, -50%);
      width: 100px;
      z-index: 102;
    }
  }

  [data-hide-and-show] {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    visibility: hidden;
  }
}

/**
 * Mixin 'paddingTopBottom' ustawia ujednolicone padding-top / padding-bottom do section.
 *
 * Użycie:
 *   @include paddingTopBottom();
 *
 * Z założenia paddingi:
 *  do max-width: 991px = 20px 
 *  od min-width: 992px = 40px 
 */
 @mixin paddingTopBottom() {
  @include rwd.breakpoint(tabletmobile) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @include rwd.breakpoint(smallUp) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/**
 * Mixin 'marginTopBottom' ustawia ujednolicone margin-top / margin-bottom do section.
 *
 * Użycie:
 *   @include marginTopBottom();
 *
 * Z założenia marginy
 *  do max-width: 991px = 20px 
 *  od min-width: 992px = 40px 
 */
 @mixin marginTopBottom() {
  @include rwd.breakpoint(tabletmobile) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  @include rwd.breakpoint(smallUp) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}