@use "../abstract/mixins" as mixin;
@use "../abstract/responsive" as rwd;

$c-amaranth-appr: #ea1e48;
$c-aqua-haze: #edf1f5;
$c-black: #000000;
$c-boston-blue: #3489c9;
$c-sunset-orange: #f95146;
$c-white: #ffffff;

.c-sliderPromoMini {
  margin: 20px 0;

  .splide.arrowsLight .splide__arrow {
    top: 30%;

    &:not(:hover)::before {
      color: var(--main-color);
    }
  }
}

.promoBoxSliderSection {  
  @include mixin.marginTopBottom();  
  @include mixin.paddingTopBottom();
  background-color: var(--promo-box-slider-bg-light);

  &.defaultView {
    min-height: 440px;
  }

  &.goWroView {
    min-height: 496px;
  }

  @include mixin.sliderLoader("[data-loader-slider]");

  
  @include rwd.breakpoint(small) {
    &.defaultView {
      min-height: 436px;
    }

    &.goWroView {
      min-height: 451px;
    }
  }
  
  @include rwd.breakpoint(tablet) {
    /* padding-top: 35px; */

    &.defaultView {
      min-height: 450px;
    }

    &.goWroView {
      min-height: 437px;
    }
  }
  
  @include rwd.breakpoint(mobile) {
    padding-left: 40px;
    padding-right: 40px;
  }
  
  @include rwd.breakpoint(largePhones) {
    &.defaultView {
      min-height: 408px;
    }

    &.goWroView {
      min-height: 419px;
    }
  }
  
  @include rwd.breakpoint(phonesUp) {
    padding-left: 60px;
    padding-right: 60px;
  }
  
  @include rwd.breakpoint(phonesDown) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include rwd.breakpoint(mediumPhones) {
    &.defaultView {
      min-height: 486px;
    }
    
    &.goWroView {
      min-height: 434px;
    }
  }

  & + .mainFooter {
    margin-top: 40px;
  }

  .topBar {
    @include mixin.flexValue(center, space-between);
    margin-bottom: 20px;

  /*   @include rwd.breakpoint(mobile) {
      margin-bottom: 15px;
    } */

    .logoLnk {
      @include rwd.breakpoint(tabletmobile) {
        display: none;
      }
    }

    .readMoreWrapper {
      margin: 0;

      .readMore {
        color: $c-white;
      }

      @include rwd.breakpoint(smallUp) {
        display: none;
      }
    }
  }

  .sectionTitle {
    margin: 0;

    a {
      color: $c-white;
    }
  }

  .boxWithImg {
    .boxTitle {
      color: $c-white;
      @include mixin.showLines(3);

      @include rwd.breakpoint(tabletmobile) {
        @include mixin.font(18px, 26px);
      }
    }

    img {
      border: none;

      @include rwd.breakpoint(smallPhones) {
        min-height: 200px;
        max-height: fit-content;
      }
    }

    &.shadowBox {
      .boxData {
        height: 100%;
      }

      .boxTitle {
        color: inherit;
      }
    }
  }

  .promoSlider {
    position: relative;

    @include rwd.breakpoint(phonesDown) {
    /*   margin-top: 20px; */
      margin-right: -15px;
    }

    .splide {
      &__track {
        @include rwd.breakpoint(tabletmobile) {
          padding-bottom: 0;
        }
      }

      &__pagination {
        @include rwd.breakpoint(tabletmobile) {
          margin-top: 25px;
        }
      }
    }

    .boxWithImg {
      height: 100%;

      .boxData {
        @include rwd.breakpoint(phonesDown) {
          padding-right: 10px;
        }
      }

      .liveIndicator {
        @include mixin.font(14px, 16px, 700, $c-white);
        @include mixin.position(absolute, -12px, 15px);
        display: inline-block;
        padding: 5px 10px 3px 9px;
        background-color: $c-amaranth-appr;

        @media screen and (max-width: 400px) {
          right: 5px;
        }
      }
    }
  }

  &.dark {
    background-color: var(--promo-box-slider-bg);

    .newslive.boxWithImg .imgWrapper picture:before {
      border-color: var(--promo-box-slider-bg);
    }
  }

  .newslive.boxWithImg .imgWrapper picture:before {
    border-color: var(--promo-box-slider-bg-light);
  }

  //ponizej wyciete z klasy dark i wyniesione dla kazdego typu tej sekcji i dark i light(default bez klasy)
  .sectionTitle:before {
    border-top-color: var(--promo-box-slider-sign-color);
  }

  .promoSlider {
    &:before {
      background: linear-gradient(
          to right,
          rgba(26, 32, 54, 0.8) 0,
          transparent 98%
        )
        no-repeat;
    }

    &:after {
      background: linear-gradient(
          to left,
          rgba(26, 32, 54, 0.8) 0,
          transparent 98%
        )
        no-repeat;
    }
  }

  .sectionTitle {
    color: $c-white;

    a {
      &:hover,
      &:focus {
        @include rwd.breakpoint(smallUp) {
          color: $c-white;
        }
      }

      &:after {
        background-color: $c-white;
      }
    }
  }

  .boxWithImg {
    .textLabel {
      color: var(--main-color);
    }
  }

  //end wyciecia z klasy dark
  // Paginacja
  .splide__pagination__page {
    border-top-color: $c-white;

    &.is-active {
      border-top-color: var(--promo-box-slider-sign-color);
    }
  }

  .splide .slideNumber {
    color: $c-white;
  }

  // Strzałki
  .promoSlider {
    .splide__arrow {
      top: 28%;

      @include rwd.breakpoint(tablet) {
        top: 36%;
      }

      &:before {
        color: $c-white;
      }

      &:hover:before,
      &:focus:before {
        color: var(--promo-box-slider-sign-color);
      }
    }

    &.arrowsMobileSlider {
      @include rwd.breakpoint(phonesDown) {
        .splide__arrow {
          &--prev {
            left: 0;
          }

          &--next {
            right: 15px;
          }
        }
      }
    }
  }

  &.promoBoxList {
    padding-left: 0;
    padding-right: 0;

    .promoList {
      li {
        margin-bottom: 30px;
      }
    }
  }
}

.promoBoxSliderSection.promoBoxSliderListing {
  background-color: $c-aqua-haze;

  @include rwd.breakpoint(mobile) {
    margin-top: 0;
    margin-bottom: 10px;
    padding: 10px 15px 30px 15px;
  }

  .topBar {
    display: block;
  }

  .splide__pagination__page.is-active {
    border-top-color: $c-white;
  }

  .splide__pagination {
    display: none;
  }

  .readMoreWrapper {
    text-align: center;
    margin-top: 20px;

    @include rwd.breakpoint(mobile) {
      text-align: left;
      display: block;
      width: 100%;
      margin-top: 10px;
    }

    .readMore {
      color: $c-black;
      padding: 10px 15px;
      border: 2px solid $c-black;
      border-radius: 4px;

      &:after {
        display: none;
      }

      @include rwd.breakpoint(mobile) {
        padding: 0;
        border: 0;
        padding-right: 20px;

        &:after {
          display: block;
          border-left-color: $c-black;
        }
      }
    }
  }

  .sectionTitle {
    &:before {
      display: none;
    }

    font-size: 35px;
    text-align: center;
    display: block;
    padding-left: 0;
    color: $c-black;

    @include rwd.breakpoint(mobile) {
      font-size: 24px;
    }
  }

  .boxWithImg {
    .boxTitle {
      color: $c-black;

      @include rwd.breakpoint(mobile) {
        @include mixin.font(16px, 20px);
      }
    }

    img {
      @include rwd.breakpoint(mobile) {
        min-height: 150px;
        height: 150px;
      }
    }
  }

  .splide .slideNumber {
    @include rwd.breakpoint(mobile) {
      display: none;
    }
  }

  .promoSlider {
    visibility: hidden;

    &.is-initialized {
      visibility: visible;
    }

    .splide__arrow:before {
      color: $c-black;
    }

    .boxTitle {
      min-height: 72px;

      @include rwd.breakpoint(mobile) {
        min-height: 60px;
      }
    }
  }
}

#forKidsSection {
  background-color: $c-boston-blue;
  position: relative;

  &::before {
    @include mixin.position(absolute, 0, false, false, 0);
    display: block;
    background-image: url("https://www.wroclaw.pl/beta2/themes/img/pattern-bg-wydarzenia-dzieci.svg");
    content: "";
    height: 100%;
    width: 100%;
  }

  .sectionTitle:before {
    border-top-color: $c-sunset-orange;
  }

  .sectionTitle {
    &:hover,
    &:focus {
      a {
        color: $c-white;
      }
    }
  }

  .splide__pagination__page.is-active {
    border-top-color: $c-sunset-orange;
  }

  .splide__pagination__page {
    &:focus {
      outline-color: $c-sunset-orange;
    }
  }

  @include rwd.breakpoint(smallUp) {
    .splide.arrowsLight .splide__arrow {
      &:hover,
      &:focus {
        &::before {
          color: $c-sunset-orange;
        }
      }
    }
  }
}
